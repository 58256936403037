//modules
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, GridItem, Box, Text, Divider, Button, useDisclosure } from '@chakra-ui/react';

//common components
import Rework from '../Modals/Rework';
import RenderModal from '../RenderModal';
import Footer from '../../../common/membershipFormComponents/Footer';
import AltFooter from '../../../common/membershipFormComponents/AltFooter';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import OtherFuel, { otherFuelFieldsValidation } from './sections/OtherFuel';
import SanctionLoad, { sanctionLoadFieldsValidation } from './sections/SanctionLoad';
import GasExtraSection, { gasExtraFieldsValidation } from './sections/GasExtraSection';
import DieselExtraSection, { dieselExtraFieldsValidation } from './sections/DieselExtraSection';
import NoteSection, { noteFieldValidation } from '../NoteSection';

//api and actions
import { patchMembershipData } from '../../../../api/membership';
import { notify } from '../../../../store/actions/globalActions';

//utils
import _ from 'lodash';
import { useYupValidationResolver } from '../../../../util/yupUtils';

//client form sections
import {
  MillInformationSection,
  millInformationFieldsValidation,
  AddressSection,
  addressFieldsValidation,
  MillSiteSection,
  millSiteFieldsValidation,
  ChairmanInformationSection,
  chairmanInfoFieldsValidation,
  ManagingDirectorSection,
  managingDirectorFieldsValidation,
  MillPresenterSection,
  millPresenterFieldsValidation,
  OtherInformationSection,
  otherInformationFieldsValidation,
  DateOfCommencementProductionSection,
  dateOfCommencementFieldsValidation,
  CapitalStructureSection,
  capitalStructureFieldsValidation,
  SourceOfCapitalSection,
  sourceOfCapitalFieldsValidation,
  BondedWarehouseApplicableSection,
  bondedWarehouseApplicableFieldsValidation,
  BondedWarehouseLicenseSection,
  bondedWarehouseLicenseFieldsValidation,
  PowerManagementLeftOver,
  powerManagementLeftOverFieldsValidation,
  DieselSection,
  dieselFieldsValidation,
  defaultDeiselFieldValues,
  GasSection,
  gasFieldsValidation,
  defaultGasFieldValues,
  MarketingFinisheProductSection,
  marketingFinishedProductFieldsValidation,
} from './clientSections';
import { useEffect } from 'react';

const validationSchema = {
  ...gasFieldsValidation,
  ...dieselFieldsValidation,
  ...addressFieldsValidation,
  ...millSiteFieldsValidation,
  ...chairmanInfoFieldsValidation,
  ...millPresenterFieldsValidation,
  ...millInformationFieldsValidation,
  ...sourceOfCapitalFieldsValidation,
  ...otherInformationFieldsValidation,
  ...capitalStructureFieldsValidation,
  ...managingDirectorFieldsValidation,
  ...dateOfCommencementFieldsValidation,
  ...powerManagementLeftOverFieldsValidation,
  ...marketingFinishedProductFieldsValidation,
  ...bondedWarehouseApplicableFieldsValidation,

  ...noteFieldValidation,
  ...gasExtraFieldsValidation,
  ...otherFuelFieldsValidation,
  ...dieselExtraFieldsValidation,
  ...sanctionLoadFieldsValidation,
};

const GeneralInormationSection = ({
  backToTable,
  nextStep,
  currStep,
  stepList,
  defaultFields,
  infoView,
  refresh,
}) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [updateData, setUpdateData] = useState();
  const [modalComponent, setModalComponent] = useState();
  const [licenseSection, setLicenseSection] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for modal

  const getValidationSchema = () => {
    if (licenseSection) {
      return yup.object().shape({ ...validationSchema, ...bondedWarehouseLicenseFieldsValidation });
    } else {
      return yup.object().shape(validationSchema);
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      diesel: defaultFields.diesel?.length
        ? [...defaultFields.diesel]
        : [...defaultDeiselFieldValues],
      gas: defaultFields.gas?.length ? [...defaultFields.gas] : [...defaultGasFieldValues],
    },
  });

  const applicable = useWatch({ control, name: 'applicable' });

  useEffect(() => {
    setLicenseSection(applicable === 'Yes');
  }, [applicable]);

  const { renewDate, actualDate, licenseDate, expectedDate, dateOfEstablishment } = defaultFields;

  const prepareData = (formdata) => {
    const {
      website,
      millName,
      millEmail,
      categoryId,
      millPhoneNo,
      yearOfOperation,
      dateOfEstablishment,
      membershipDate,

      hoName,
      hoRoadNo,
      hoHouseNo,
      hoDistrict,
      hoPostOffice,
      hoPoliceStation,

      msEmail,
      msPhoneNo,
      msAddress,
      msDistrict,
      msTotalArea,
      msHoldingNo,
      msTotalBuilding,
      msPoliceStation,

      chairmanName,
      chairmanEmail,
      chairmanMobileNo,
      chairmanOfficeTelephone,
      chairmanResidenceTelephone,

      mdName,
      mdEmail,
      mdMobileNo,
      mdOfficeTelephone,
      mdResidenceTelephone,

      mpName,
      mpEmail,
      mpMobileNo,
      mpOfficeTelephone,
      mpResidenceTelephone,

      natureOfCompany,
      mainManufactureActivity,

      expectedDate,
      actualDate,

      paidupCapitalInTK,
      paidupCapitalInUS,
      authorizedCapitalInTK,
      authorizedCapitalInUS,

      etp,
      other,
      sponsors,
      bankFinance,
      publicIssue,
      workingCapital,
      totalCostOfProject,
      participationShareMarket,

      local,
      exportable,

      licenseNo,
      renewDate,
      applicable,
      licenseDate,

      gas,
      diesel,
      approvedGasLoad,
      approvedElectricityLoad,

      sancGas,
      numberGas,
      capacityGas,
      numberDiesel,
      capacityDiesel,
      sancElectricity,
      capacityOtherFuel,
      countryOfOriginGas,
      countryOfOriginDiesel,
      countryOfOriginOtherFuel,

      note,
    } = formdata;

    const sendData = {
      website,
      millName,
      categoryId,
      yearOfOperation,
      dateOfEstablishment,
      membershipDate,

      hoInfo: {
        hoName,
        hoRoadNo,
        millEmail,
        hoHouseNo,
        hoDistrict,
        millPhoneNo,
        hoPostOffice,
        hoPoliceStation,
      },

      msInfo: {
        msEmail,
        msPhoneNo,
        msAddress,
        msDistrict,
        msHoldingNo,
        msTotalArea,
        msTotalBuilding,
        msPoliceStation,
      },

      chairmanInfo: {
        chairmanName,
        chairmanEmail,
        chairmanMobileNo,
        chairmanOfficeTelephone,
        chairmanResidenceTelephone,
      },

      mdInfo: {
        mdName,
        mdEmail,
        mdMobileNo,
        mdOfficeTelephone,
        mdResidenceTelephone,
      },

      millPresentatorInfo: {
        mpName,
        mpEmail,
        mpMobileNo,
        mpOfficeTelephone,
        mpResidenceTelephone,
      },

      otherInfo: {
        natureOfCompany,
        mainManufactureActivity,
        note,
      },

      dateOfCommencementOfProduction: {
        actualDate,
        expectedDate,
      },

      capitalStructure: {
        paidupCapitalInTK,
        paidupCapitalInUS,
        authorizedCapitalInTK,
        authorizedCapitalInUS,
      },

      sourceOfCapital: {
        etp,
        other,
        sponsors,
        bankFinance,
        publicIssue,
        workingCapital,
        totalCostOfProject,
        participationShareMarket,
      },

      marketingOfFinishedProducts: {
        local,
        exportable,
      },

      bondedWarehouseLicenseNo: {
        licenseNo,
        renewDate,
        applicable,
        licenseDate,
      },

      powerManagement: {
        gas,
        diesel,
        approvedGasLoad,
        approvedElectricityLoad,
      },

      powerManagementSummary: {
        sancGas,
        numberGas,
        capacityGas,
        numberDiesel,
        capacityDiesel,
        sancElectricity,
        capacityOtherFuel,
        countryOfOriginGas,
        countryOfOriginDiesel,
        countryOfOriginOtherFuel,
      },
    };

    return sendData;
  };

  const handleQuery = async (queryData, changeStep, flag) => {
    if (flag) {
      const res = await patchMembershipData({
        formData: prepareData(queryData),
        step: currStep,
        param: uid,
      });
      if (res.data?.status == 200) {
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        changeStep();
      }
    } else {
      if (
        _.isEqual(queryData, {
          ...defaultFields,
          renewDate: new Date(renewDate),
          actualDate: new Date(actualDate),
          licenseDate: new Date(licenseDate),
          expectedDate: new Date(expectedDate),
          dateOfEstablishment: new Date(dateOfEstablishment),
        }) === false
      ) {
        const res = await patchMembershipData({
          formData: prepareData(queryData),
          step: currStep,
          param: uid,
        });
        if (res.data?.status == 200) {
          dispatch(
            notify({
              title: 'Success',
              description: res.data.msg,
              status: 'success',
              duration: 5000,
            }),
          );
          changeStep();
        }
      } else {
        changeStep();
      }
    }
  };

  const onSubmit = (formdata) => {
    if (
      _.isEqual(formdata, {
        ...defaultFields,
        renewDate: new Date(renewDate),
        actualDate: new Date(actualDate),
        licenseDate: new Date(licenseDate),
        expectedDate: new Date(expectedDate),
        dateOfEstablishment: new Date(dateOfEstablishment),
      }) === false
    ) {
      setUpdateData(formdata);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const onForwardSubmit = (formdata) => {
    handleQuery(formdata, nextStep);
  };

  const handleRework = () => {
    setModal('ReworkModal');
    setModalComponent(<Rework close={onClose} backToTable={backToTable} />);
    onOpen();
  };

  const handleReject = () => {
    setModal('RejectModal');
    onOpen();
  };

  const rejectMembershipRequest = async () => {
    const res = await patchMembershipData({ param: uid, reject: true });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      onClose();
      backToTable();
    }
  };

  const handleBackToTable = () => {
    setModal('BackToTableAlert');
    onOpen();
  };

  return (
    <Box>
      <MembershipFormWrapper formName="General Information">
        {/* client data */}
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem>
            <MillInformationSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <AddressSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <MillSiteSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem>
            <ChairmanInformationSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <ManagingDirectorSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem>
            <MillPresenterSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <DateOfCommencementProductionSection
              register={register}
              control={control}
              errors={errors}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <CapitalStructureSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <SourceOfCapitalSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem>
            <MarketingFinisheProductSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <OtherInformationSection register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>
        <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">
          Power Management Information
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <DieselSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <GasSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <PowerManagementLeftOver register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <BondedWarehouseApplicableSection
              register={register}
              control={control}
              errors={errors}
            />
            {applicable === 'Yes' ? (
              <BondedWarehouseLicenseSection
                register={register}
                control={control}
                errors={errors}
              />
            ) : null}
          </GridItem>
        </Grid>

        {/* admin inputs */}
        <Text
          mb={2}
          pb={2}
          mt={10}
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          color="textSecondary"
          borderStyle="dashed"
          borderBottom="2px solid #6B7982">
          Additional Information
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <GasExtraSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <DieselExtraSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <OtherFuel register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <SanctionLoad register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>
        <Box mt={3} p={3} bg="white">
          <NoteSection register={register} control={control} errors={errors} />
        </Box>
      </MembershipFormWrapper>
      {infoView ? (
        <AltFooter back={backToTable} buttonLoading={isSubmitting}>
          <Button
            type="submit"
            variant="footerUpdate"
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            Update
          </Button>
        </AltFooter>
      ) : (
        <Footer
          currStep={currStep}
          stepList={stepList}
          rework={handleRework}
          reject={handleReject}
          back={handleBackToTable}
          buttonLoading={isSubmitting}
          next={(event) => {
            event.preventDefault();
            handleSubmit(onForwardSubmit)();
          }}
        />
      )}
      <RenderModal
        modal={modal}
        step={currStep}
        isOpen={isOpen}
        onClose={onClose}
        backToTable={backToTable}
        modalComponent={modalComponent}
        confirmReject={rejectMembershipRequest}
        confirmSubmit={() => handleQuery(updateData, refresh, true)}
      />
    </Box>
  );
};

export default GeneralInormationSection;
