import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';
import { membershipRequestStatus } from '../../../../../constants/membershipConstants';

const getYearList = (year) => {
  let years = [];
  let currYear = year ? parseInt(year) : parseInt(new Date().getFullYear());
  for (let i = 0; i < 30; i++) {
    years.push({ name: `${currYear + i}`, value: `${currYear + i}` });
  }

  return years;
};

const fields = [
  {
    fieldType: 'radio',
    colSpan: 1,
    label: 'Membership Type',
    isRequired: true,
    name: 'membershipType',
    choices: [
      { name: 'Associate', value: 'ASSOCIATE' },
      { name: 'General', value: 'GENERAL' },
    ],
  },
  {
    fieldType: 'select',
    colSpan: 1,
    label: 'Membership Status',
    isRequired: true,
    name: 'membershipStatus',
    choices: [
      { name: membershipRequestStatus.PENDING, value: membershipRequestStatus.PENDING },
      { name: membershipRequestStatus.APPLIED, value: membershipRequestStatus.APPLIED },
      { name: membershipRequestStatus.INREVIEW, value: membershipRequestStatus.INREVIEW },
      { name: membershipRequestStatus.REWORK, value: membershipRequestStatus.REWORK },
      {
        name: membershipRequestStatus.PAYMENTPENDING,
        value: membershipRequestStatus.PAYMENTPENDING,
      },
      { name: membershipRequestStatus.APPROVED, value: membershipRequestStatus.APPROVED },
    ],
  },
  {
    fieldType: 'select',
    colSpan: 1,
    label: 'Membership upto',
    isRequired: true,
    name: 'expiryYear',
    choices: getYearList(),
  },
];

const yearRestrictedFields = (expYear) => {
  return fields.map((item) => {
    if (item.name === 'expiryYear') {
      return {
        ...item,
        choices: getYearList(expYear),
      };
    } else {
      return item;
    }
  });
};

export const membershipFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
};

const MembershipSection = (props) => {
  return <FormSection sectionColumn={1} formFields={yearRestrictedFields(props.year)} {...props} />;
};

export default MembershipSection;
