import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { dateValidation, useYupValidationResolver } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';
import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import FormButton from '../../../../common/FormButton';
import DialogueBox from '../../../../common/DialogueBox';
import { adjustReceiptData } from '../../../../../api/accounts';
import { useDispatch } from 'react-redux';
import { notify } from '../../../../../store/actions/globalActions';
import ChequeInfoSection, { chequeInfoFieldsValidation } from './ChequeInfoSection';
import { CgPrinter } from 'react-icons/cg';
import { useReactToPrint } from 'react-to-print';
import MoneyReceipt from '../../MoneyReceipt';

const TransactionDetails = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const memMoneyReceiptRef = useRef();
  const [method, setMethod] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [moneyReceiptData, setMoneyReceiptData] = useState();

  const printMoneyReceipt = useReactToPrint({
    documentTitle: `BTMA-Money-Receipt-${data?.millName}`,
    content: () => memMoneyReceiptRef.current,
  });

  const paymentFields = [
    {
      colSpan: 1,
      fieldType: 'date',
      name: 'issueDate',
      label: 'Date',
      isRequired: true,
    },
    {
      colSpan: 1,
      name: 'previousDue',
      type: 'number',
      label: 'Arrears',
      isRequired: true,
    },
    {
      colSpan: 1,
      name: 'currentAmount',
      type: 'number',
      label: 'Current Receivable',
      isRequired: true,
    },
    {
      colSpan: 1,
      name: 'paidAmount',
      type: 'number',
      label: 'Total Received',
      isRequired: true,
    },
    {
      colSpan: 1,
      name: 'currentDue',
      type: 'number',
      label: 'Due',
      isRequired: true,
    },
    {
      fieldType: 'select',
      colSpan: 1,
      label: 'Payment Method',
      isRequired: true,
      name: 'paymentMethod',
      choices: [
        { name: 'Cash', value: 'CASH' },
        { name: 'Cheque', value: 'CHEQUE' },
        { name: 'EFT', value: 'EFT' },
        { name: 'MFS', value: 'MFS' },
      ],
    },
  ];

  const otherFields = [
    {
      colSpan: 1,
      type: 'text',
      name: 'period',
      label: 'Segment',
      isRequired: true,
    },
    {
      colSpan: 1,
      type: 'text',
      name: 'remarks',
      label: 'Remarks',
      isRequired: true,
    },
    {
      colSpan: 1,
      isRequired: true,
      fieldType: 'select',
      name: 'paymentStatus',
      label: 'Status',
      choices: [
        { name: 'Paid', value: 'PAID' },
        { name: 'Unpaid', value: 'UNPAID' },
      ],
    },
    {
      colSpan: 3,
      type: 'text',
      name: 'notes',
      label: 'Notes',
      isRequired: false,
    },
  ];

  const validatonSchema = {
    [paymentFields[0].name]: dateValidation.required().label(paymentFields[0].label),
    [paymentFields[1].name]: yup
      .number()
      .typeError(`${paymentFields[1].label} must be a number`)
      .integer()
      .min(0)
      .required()
      .label(paymentFields[1].label),
    [paymentFields[2].name]: yup
      .number()
      .typeError(`${paymentFields[2].label} must be a number`)
      .integer()
      .min(0)
      .required()
      .label(paymentFields[2].label),
    [paymentFields[3].name]: yup
      .number()
      .typeError(`${paymentFields[3].label} must be a number`)
      .integer()
      .min(0)
      .required()
      .label(paymentFields[3].label),
    [paymentFields[4].name]: yup
      .number()
      .typeError(`${paymentFields[4].label} must be a number`)
      .integer()
      .min(0)
      .required()
      .label(paymentFields[4].label),
    [paymentFields[5].name]: yup.string().required().label(paymentFields[5].label),
    [otherFields[0].name]: yup.string().required().label(otherFields[0].label),
    [otherFields[1].name]: yup.string().required().label(otherFields[1].label),
    [otherFields[2].name]: yup.string().required().label(otherFields[2].label),
  };

  const chequeValidationSchema = {
    ...chequeInfoFieldsValidation(method),
  };

  const getValidatonSchema = () => {
    if (!method || method === 'CASH') return yup.object().shape(validatonSchema);
    else return yup.object().shape({ ...validatonSchema, ...chequeValidationSchema });
  };

  const resolver = useYupValidationResolver(getValidatonSchema());
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: {
      issueDate: data?.issueDate,
      previousDue: data?.previousDue,
      currentAmount: data?.currentAmount,
      paidAmount: data?.paidAmount,
      currentDue: data?.currentDue,
      paymentMethod: data?.paymentMethod,
      chequeNo: data?.chequeNo,
      chequeDate: data?.chequeDate,
      period: data?.period,
      remarks: data?.remarks,
      paymentStatus: data?.paymentStatus,
      notes: data?.notes,
    },
  });

  const paymentMethod = useWatch({ control, name: 'paymentMethod' });

  useEffect(() => {
    setMethod(paymentMethod);
  }, [paymentMethod]);

  const onsubmit = (formdata) => {
    setFormData(formdata);
    setIsOpen(true);
  };

  const confirmSubmit = async () => {
    setIsOpen(false);
    setIsSubmitting(true);
    const res = await adjustReceiptData(data?.trxId, formData);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
    setIsSubmitting(false);
    onClose();
  };

  const handlePrint = () => {
    setMoneyReceiptData({
      slNo: data?.trxId,
      period: data?.period,
      due: data?.currentDue,
      total: data?.paidAmount,
      mrDate: data?.issueDate,
      receivedFrom: data?.millName,
      amount: data?.paidAmount,
      chequeNo: data?.chequeNo,
      chequeDated: data?.chequeDate,
      paymentMethod: data?.paymentMethod,
      paymentFor: data?.remarks,
      paymentChoices: [
        'Membership Subscription',
        'Membership Renewal',
        'Arrear Adjustment',
        'Advertisement',
        'Installment',
      ],
    });
    setTimeout(() => {
      printMoneyReceipt();
    }, 500);
  };

  return (
    <VStack width="100%" alignItems="stretch">
      <Box display="none">
        <MoneyReceipt ref={memMoneyReceiptRef} data={moneyReceiptData} />
      </Box>
      <DialogueBox
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmSubmit}
        close={() => setIsOpen(false)}
        alertMessage="Are you sure you want submit this information?"
      />
      <FormSection
        secondary
        errors={errors}
        control={control}
        sectionColumn={3}
        register={register}
        formFields={paymentFields}
      />
      {method && method !== 'CASH' && (
        <ChequeInfoSection control={control} register={register} errors={errors} />
      )}
      <FormSection
        secondary
        errors={errors}
        control={control}
        sectionColumn={3}
        register={register}
        formFields={otherFields}
      />
      <HStack alignItems="end" justifyContent="center">
        <FormButton onClick={handlePrint} leftIcon={<Icon as={CgPrinter} w={7} h={7} />}>
          Print
        </FormButton>
        <FormButton onClick={() => handleSubmit(onsubmit)()} isLoading={isSubmitting}>
          Submit
        </FormButton>
      </HStack>
    </VStack>
  );
};

export default TransactionDetails;
