import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Box, useDisclosure } from '@chakra-ui/react';

import Password from '../Modals/Password';
import PasswordModal from '../Modals/PasswordModal';
import DialogueBox from '../../../common/DialogueBox';
import DataTable from '../../../common/dataTable/DataTable';

import {
  FETCH_MEMBERS,
  REVOKE_MEMBERS,
  FETCH_MEMBERSHIP_REQUEST,
} from '../../../../store/actions/actionTypes';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
  MEMBERSHIP_TYPE_SELECTION,
  MEMBERSHIP_CATEGORY_SELECTION,
} from '../../../../constants/dataTableConstants';
import { notify } from '../../../../store/actions/globalActions';
import { MEMBER_INFO_URL } from '../../../../constants/routerUrl';
import { getMembers, revokeMembership } from '../../../../api/membership';

const ActiveMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [modalComponent, setModalComponent] = useState();
  const user = useSelector((state) => state.auth.user);
  const data = useSelector((state) => state.membership.members);
  const revokeList = useSelector((state) => state.membership.revokeList);
  const { onOpen, isOpen, onClose } = useDisclosure(); //control hook for modal

  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);

  const columns = [
    {
      isCheckBox: true,
      accessor: 'id',
    },
    {
      header: 'ID',
      accessor: 'membershipNo',
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    {
      header: 'Type',
      accessor: 'membershipType',
    },
    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Expiry Date',
      accessor: 'membershipExpiryDate',
      isDate: true,
    },
    {
      header: 'Email',
      accessor: 'millEmail',
    },
    {
      header: 'Contact',
      accessor: 'millPhoneNo',
    },
    {
      header: 'Status',
      accessor: 'membershipStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'membershipStatus',
      isButton: true,
      actionScheme: [{ group: 'APPROVED', variant: 'read', action: 'VIEW' }],
    },
  ];

  const handleView = async ({ rowObject }) => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
    history.push(`${MEMBER_INFO_URL}/${rowObject.uid}`);
  };

  const handleTableAction = (ids, clearSelection, action) => {
    switch (action) {
      case 'REVOKE':
        dispatch({ type: REVOKE_MEMBERS, payload: ids });
        setModal('RevokeAlert');
        setModalComponent(
          <Password executeAction={confirmRevoke} clearSelection={clearSelection} />,
        );
        onOpen();
        break;
    }
    dispatch({ type: REVOKE_MEMBERS, payload: ids });
    setModal('RevokeAlert');
    setModalComponent(<Password executeAction={confirmRevoke} clearSelection={clearSelection} />);
    onOpen();
  };

  const confirmRevoke = async (password, list) => {
    const res = await revokeMembership({ userId: user.userId, password, idList: list });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      const response = await getMembers({ page: 1, pageSize: 10 }, 'ACTIVE');
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_MEMBERS, payload: response.data });
      }
    }
    onClose();
  };

  const actionObject = {
    VIEW: handleView,
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected }) => {
    dispatch({ type: FETCH_MEMBERS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      category: selected?.['Mill Type'],
      type: selected?.['Membership Type'],
    };
    const res = await getMembers(params, 'ACTIVE');
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERS, payload: { members: [] } });
    }
  };

  const handleSearch = async ({ searchKey, selected }) => {
    dispatch({ type: FETCH_MEMBERS, payload: null });
    const params = {
      searchKey,
      type: selected?.['Membership Type'],
      category: selected?.['Mill Type'],
    };
    const res = await getMembers(params, 'ACTIVE');
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERS, payload: { members: [] } });
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'RevokeAlert':
        return (
          <DialogueBox
            close={onClose}
            mountControl={isOpen}
            alertTitle="Revoke Membership"
            alertMessage="Are you sure you want to revoke membership for the following mills?"
            performAction={() => {
              setModal('PasswordModal');
              onOpen();
            }}>
            <Box bg="secondary" p={4} mt={2} maxH="15vh" overflowX="auto">
              {data?.members?.map((item) =>
                revokeList?.includes(item?.id) ? (
                  <Text fontSize="lg">
                    {item?.membershipNo} - {item?.millName}
                  </Text>
                ) : null,
              )}
            </Box>
          </DialogueBox>
        );
      case 'PasswordModal':
        return (
          <PasswordModal mountControl={isOpen} close={onClose}>
            {modalComponent}
          </PasswordModal>
        );
      default:
        return null;
    }
  };

  return (
    <Box p={4}>
      <DataTable
        topPanel
        keySearch
        isPaginated
        columns={columns}
        data={data?.members}
        search={handleSearch}
        actions={actionObject}
        caption="Active Members"
        currentPage={data?.page}
        paginate={handlePagination}
        entries={data?.totalEntries}
        rowSelectScheme={['REVOKE']}
        currentPageSize={data?.pageSize}
        onActionClick={handleTableAction}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        selectOptions={[MEMBERSHIP_TYPE_SELECTION, MEMBERSHIP_CATEGORY_SELECTION]}
      />
      {renderModal()}
    </Box>
  );
};

export default ActiveMembers;
