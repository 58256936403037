import React from 'react';
import CostBreakdown from './CostBreakdown';
import DutyCostBreakdown from './DutyCostBreakdown';
import { renderDate } from '../../../util/formatUtils';
import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import PrintReceiptHeader from '../../snippets/PrintReceiptHeader';
import { dutyCertCategories } from '../../../constants/certificateConstant';

const CertificateBill = React.forwardRef(({ title, costs, data, isFromBlankCert }, ref) => (
  <Box p={6} ref={ref}>
    <PrintReceiptHeader />
    <HStack w="100%" justifyContent="space-between" alignItems="flex-start">
      <VStack spacing={0} alignItems="flex-start" w="35%">
        <Text fontSize="sm" fontWeight="bold">
          Managing Director
        </Text>
        <Text fontSize="sm">{data?.millName}</Text>
        <Text fontSize="sm">{data?.millAddress}</Text>
      </VStack>
      <VStack spacing={0} alignItems="flex-start">
        <Text fontSize="sm">Bill No: {data?.billNo}</Text>
        <Text fontSize="sm">Date: {renderDate(data?.billDate)}</Text>
      </VStack>
    </HStack>

    {costs?.certFor?.toUpperCase() === 'DUTY' ? (
      isFromBlankCert ? (
        <CostBreakdown data={costs} billFor={title} isFromBlankCert />
      ) : (
        <DutyCostBreakdown data={costs} billFor={title} />
      )
    ) : (
      <CostBreakdown data={costs} billFor={title} />
    )}

    <VStack w="100%" alignItems="flex-end" mt={10}>
      <VStack>
        <Text
          p={2}
          fontSize="lg"
          width="300px"
          fontWeight="bold"
          textAlign="center"
          borderBottom="2px solid black"></Text>
        <Text fontWeight="bold">Authorized Signature</Text>
      </VStack>
    </VStack>

    {costs?.dutyCertCost?.category === dutyCertCategories.A ? (
      <Box mt={10}>
        <Text w="fit-content" fontSize="sm" fontWeight="bold" borderBottom="1px solid black">
          যন্ত্রপাতি ও যন্ত্রাংশ:
        </Text>
        <Text fontSize="sm">
          সমিতি কর্তৃক জারীকৃত প্রত্যেকটি প্রত্যয়ন পত্রের এল/সি এমাউন্টের/কনসাইনমেন্ট ভ্যালুর প্রতি
          এক লক্ষ টাকা (Up to Taka one lac) পর্যন্ত ৫০০ (পাঁচশত) টাকা, এবং তৎপরবর্তী ২৫০০০.০০ এর
          জন্য ১২৫ (একশত পঁচিশ) টাকা হারে প্রদান করার জন্য সবিনয় অনুরোধ করছি।
        </Text>
      </Box>
    ) : costs?.dutyCertCost?.category === dutyCertCategories.B ? (
      <Box mt={10}>
        <Text w="fit-content" fontSize="sm" fontWeight="bold" borderBottom="1px solid black">
          রং রসায়নের ক্ষেত্রে:
        </Text>
        <Text fontSize="sm">
          সমিতি কর্তৃক জারীকৃত প্রত্যেকটি প্রত্যয়ন পত্রের এল/সি এমাউন্টের/কনসাইনমেন্ট ভ্যালুর প্রতি
          এক লক্ষ টাকা (Up to Taka one lac) পর্যন্ত ২০০ (দুইশত) টাকা, এবং তৎপরবর্তী ২৫০০০.০০ এর জন্য
          ৫০ (পঞ্চাশ) টাকা হারে প্রদান করার জন্য সবিনয় অনুরোধ করছি।
        </Text>
      </Box>
    ) : costs?.certFor?.toUpperCase() === 'DUTY' && !isFromBlankCert ? (
      <Box mt={10}>
        <Text fontSize="sm">
          পলিয়েস্টার স্ট্যাপল ফাইবার, ভিসকস স্ট্যাপল ফাইবার, টেনসিল ও ফ্লাক্স ফাইবার এবং অন্যান্য
          ফাইবার আমদানীর ক্ষেত্রে ১০০ টন থেকে উপরের দিকে যে কোন পরিমানের জন্য ১০০০০ (দশ হাজার) টাকা
          এবং ১০০ টনের নিচে যে কোন পরিমানের জন্য ৫০০০ (পাঁচ হাজার) টাকা হারে প্রদান করার জন্য সবিনয়
          অনুরোধ করছি।
        </Text>
      </Box>
    ) : null}
  </Box>
));

CertificateBill.displayName = 'CertificateBill';

export default CertificateBill;
