import React from 'react';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const BasicInfo = (props) => {
  const fields = [
    {
      fieldType: 'select',
      colSpan: 1,
      label: 'Mill Type',
      name: 'categoryId',
      isRequired: true,
      choices: props.categoryOptions,
    },
    {
      colSpan: 1,
      name: 'millName',
      type: 'text',
      isRequired: true,
      label: 'Mill Name',
    },
    {
      fieldType: 'date',
      colSpan: 1,
      name: 'membershipDate',
      label: 'Membership Date',
      isRequired: true,
    },
  ];

  return (
    <FormSection sectionName="Basic Information" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default BasicInfo;
