export const LOGS_TABLE_DISPLAY_SIZE = [10, 20, 30, 40, 50];
export const MEMBERSHIP_TYPE_SELECTION = {
  name: 'Membership Type',
  choices: [
    { name: 'GENERAL', value: 'GENERAL' },
    { name: 'ASSOCIATE', value: 'ASSOCIATE' },
  ],
};
export const MEMBERSHIP_CATEGORY_SELECTION = {
  name: 'Mill Type',
  choices: [
    { name: 'Yarn Manufacturer', value: 1 },
    { name: 'Fabric Manufacturer', value: 2 },
    { name: 'Textile Product Processor', value: 3 },
  ],
};
export const MEMBERSHIP_APPLICATION_STATUS = {
  name: 'Application Status',
  choices: [
    { name: 'APPLIED', value: 'APPLIED' },
    { name: 'IN REVIEW', value: 'IN REVIEW' },
    { name: 'REWORK', value: 'REWORK' },
  ],
};
export const BADGE_STATE_COLORS = [
  { group: 'PENDING', color: 'purple' },
  { group: 'APPLIED', color: 'gray' },
  { group: 'IN REVIEW', color: 'yellow' },
  { group: 'PAYMENT PENDING', color: 'orange' },
  { group: 'REWORK', color: 'blue' },
  { group: 'REJECTED', color: 'red' },
  { group: 'APPROVED', color: 'green' },
  { group: 'PAID', color: 'green' },
  { group: 'UNPAID', color: 'red' },
  { group: 'CASH', color: 'blackAlpha' },
  { group: 'CHEQUE', color: 'purple' },
  { group: 'EFT', color: 'blue' },
  { group: 'MFS', color: 'orange' },
  { group: 'ACTIVE', color: 'green' },
  { group: 'DISABLED', color: 'red' },
];

export const NOTIFICATION_TYPE = {
  name: 'Type',
  choices: [
    { name: 'Email', value: 'EMAIL' },
    {
      name: 'Sms',
      value: 'SMS',
    },
  ],
};

export const NOTIFICATION_CONTEXT = {
  name: 'Context',
  choices: [{ name: 'DUTY_PURCHASE', value: 'DUTY_PURCHASE' }],
};

export const NOTIFICATION_STATUS = {
  name: 'Status',
  choices: [
    { name: 'Pending', value: 'PENDING' },
    { name: 'Sent', value: 'SENT' },
    {
      name: 'Failed',
      value: 'FAILED',
    },
  ],
};
